function handleDataScopePartner(arrPartner, type, objScope) {
    let arr = [];
    switch (type) {
        case 'register':
            arrPartner.forEach((lv1) => {
                if (!lv1?.data?.length) {
                    if (lv1.partner_corresponding) {
                        arr.push({
                            ...(lv1.idResgited && { id: lv1.idResgited }),
                            master_order_scope_id: lv1.id,
                            partner_id: lv1.partner_corresponding.id,
                            tool_id: lv1.tool_corresponding?.id
                        });
                    }
                }
                lv1.data.forEach((lv2) => {
                    if (!lv2?.data?.length) {
                        if (lv2.partner_corresponding) {
                            arr.push({
                                ...(lv2.idResgited && { id: lv2.idResgited }),
                                master_order_scope_id: lv2.id,
                                partner_id: lv2.partner_corresponding.id,
                                tool_id: lv2.tool_corresponding?.id
                            });
                        }
                    }
                    lv2.data.forEach((lv3) => {
                        if (!lv3?.data?.length) {
                            if (lv3.partner_corresponding) {
                                arr.push({
                                    ...(lv3.idResgited && { id: lv3.idResgited }),
                                    master_order_scope_id: lv3.id,
                                    partner_id: lv3.partner_corresponding.id,
                                    tool_id: lv3.tool_corresponding?.id
                                });
                            }
                        }
                        lv3.data?.forEach((lv4) => {
                            if (!lv4?.data?.length) {
                                if (lv4.partner_corresponding) {
                                    arr.push({
                                        ...(lv4.idResgited && { id: lv4.idResgited }),
                                        master_order_scope_id: lv4.id,
                                        partner_id: lv4.partner_corresponding.id,
                                        tool_id: lv4.tool_corresponding?.id
                                    });
                                }
                            }
                        });
                    });
                });
            });
            break;
        case 'select':
            arrPartner.forEach((lv1) => {
                if (lv1.partner_corresponding) {
                    arr.push({
                        ...(lv1.idResgited && { id: lv1.idResgited }),
                        master_order_scope_id: lv1.id,
                        partner_id: lv1.partner_corresponding,
                        tool_id: lv1.tool_corresponding ?? null
                    });
                }
                lv1.data.forEach((lv2) => {
                    if (lv2.partner_corresponding) {
                        arr.push({
                            ...(lv2.idResgited && { id: lv2.idResgited }),
                            master_order_scope_id: lv2.id,
                            partner_id: lv2.partner_corresponding,
                            tool_id: lv2.tool_corresponding ?? null
                        });
                    }
                    lv2.data.forEach((lv3) => {
                        if (lv3.partner_corresponding) {
                            arr.push({
                                ...(lv3.idResgited && { id: lv3.idResgited }),
                                master_order_scope_id: lv3.id,
                                partner_id: lv3.partner_corresponding,
                                tool_id: lv3.tool_corresponding ?? null
                            });
                        }
                        lv3.data?.forEach((lv4) => {
                            if (lv4.partner_corresponding) {
                                arr.push({
                                    ...(lv4.idResgited && { id: lv4.idResgited }),
                                    master_order_scope_id: lv4.id,
                                    partner_id: lv4.partner_corresponding,
                                    tool_id: lv4.tool_corresponding ?? null
                                });
                            }
                        });
                    });
                });
            });
            break;
        case 'remove':
            arrPartner.forEach((lv1) => {
                arr.push({
                    ...(lv1.idResgited && { id: lv1.idResgited }),
                    master_order_scope_id: lv1.id,
                    partner_id: lv1.partner_corresponding,
                    tool_id: lv1.tool_corresponding
                });

                lv1.data.forEach((lv2) => {
                    arr.push({
                        ...(lv2.idResgited && { id: lv2.idResgited }),
                        master_order_scope_id: lv2.id,
                        partner_id: lv2.partner_corresponding,
                        tool_id: lv2.tool_corresponding
                    });
                    lv2.data.forEach((lv3) => {
                        arr.push({
                            ...(lv3.idResgited && { id: lv3.idResgited }),
                            master_order_scope_id: lv3.id,
                            partner_id: lv3.partner_corresponding,
                            tool_id: lv3.tool_corresponding
                        });
                        lv3.data?.forEach((lv4) => {
                            arr.push({
                                ...(lv4.idResgited && { id: lv4.idResgited }),
                                master_order_scope_id: lv4.id,
                                partner_id: lv4.partner_corresponding,
                                tool_id: lv4.tool_corresponding
                            });
                        });
                    });
                });
            });

            arr.forEach((ia) => {
                if (ia.master_order_scope_id === objScope.id) {
                    ia.partner_id = objScope.partner_corresponding;
                    ia.tool_id = objScope.tool_corresponding;
                }
            });

            break;
    }

    return arr;
}

export { handleDataScopePartner };
