import moment from 'moment';
import i18n from '@/i18n';
import { LIST_GIVE_AWAY_RELATED } from '@/components/Campaign/Tab7/constants';
import { STR_DATE, STR_DATE_TIME } from '@/utils/format';
import { handleMapDataPrizes } from '@/components/Campaign/TabPrize/index.js';
import { handleDataScopePartner } from '@/components/Campaign/TabTool/index.js';

function initObjectInformationIP(data = null) {
    let objInfo = {
        master_id: null,
        value: '',
        note: {
            detail: ''
        },
        checkedBox: [],
        children_data: []
    };
    if (!data) {
        objInfo = {
            ...objInfo
        };
    } else {
        objInfo = {
            ...objInfo,
            master_id: data?.id ?? null,
            value: data?.value ?? '',
            note: {
                detail: data?.note?.detail ?? ''
            },
            checkedBox: data?.children_data?.map((dataChil) => dataChil.id) ?? [],
            children_data: data?.children_data?.map((dataChil) => {
                return {
                    master_id: dataChil.id,
                    value: dataChil.value,
                    note: dataChil.note,
                    children_data: dataChil.children_data
                };
            })
        };
    }
    return objInfo;
}

function initObjectSponsor(data = null) {
    let objSponsor = {
        master_id: null,
        value: '',
        note: {
            circulation_number: '',
            circulation_distribution: '',
            up_manufacturers: '',
            up_manufacturers_name: '',
            content_other: '',
            configCirculationNumber: { isMaxLength: false, error: false, errorField: i18n.t('pageCampaign.circulationNumber') },
            configUpManufacturers: { isMaxLength: false, error: false, errorField: i18n.t('pageCampaign.tie-upManufacturers') }
        },
        checkedBox: [],
        children_data: []
    };
    if (!data) {
        objSponsor = {
            ...objSponsor
        };
    } else {
        objSponsor = {
            ...objSponsor,
            master_id: data?.id ?? null,
            value: data?.value ?? '',
            note: {
                circulation_number: data?.note?.circulation_number ?? '',
                circulation_distribution: data?.note?.circulation_distribution ?? '',
                up_manufacturers: data?.note?.up_manufacturers ?? '',
                up_manufacturers_name: data?.note?.up_manufacturers_name ?? '',
                content_other: data?.note?.content_other ?? '',
                configCirculationNumber: { isMaxLength: false, error: false, errorField: i18n.t('pageCampaign.circulationNumber') },
                configUpManufacturers: { isMaxLength: false, error: false, errorField: i18n.t('pageCampaign.tie-upManufacturers') }
            },
            checkedBox: data?.children_data?.map((dataChil) => dataChil.id) ?? [],
            children_data: data?.children_data?.map((dataChil) => {
                return {
                    master_id: dataChil.id,
                    value: dataChil.value,
                    note: dataChil.note,
                    children_data: dataChil.children_data
                };
            })
        };
    }
    return objSponsor;
}

function initObjectTargetProduct(data = null, type = 'watch') {
    let objTargetProduct = {
        master_id: null,
        value: '',
        note: {
            detail: ''
        },
        children_data: []
    };
    if (!data) {
        objTargetProduct = {
            ...objTargetProduct
        };
    } else {
        const conditionData = data?.children_data ?? data?.data;
        objTargetProduct = {
            ...objTargetProduct,
            master_id: data?.id ?? null,
            value: data?.value ?? '',
            note: {
                detail: data?.note?.detail ?? ''
            },
            children_data:
                type == 'watch'
                    ? conditionData?.map((dataChil) => {
                          return {
                              master_id: dataChil.id,
                              value: dataChil.value,
                              note: {
                                  product_name: dataChil?.note?.product_name ?? ''
                              },
                              children_data: dataChil?.data ?? []
                          };
                      })
                    : []
        };
    }
    return objTargetProduct;
}

function initObjectClient(data = null) {
    let objClient = {
        id: null,
        client_id: null,
        configFieldSelectClient: {
            trackBy: 'id',
            label: 'name',
            errorField: 'エンドクライアント名',
            valueSync: null,
            error: false,
            isRemove: false,
            isRequired: false,
            typesValidate: ['']
        },
        client_pic_id: null,
        configFieldSelectClientPic: {
            trackBy: 'id',
            label: 'name',
            errorField: '担当	',
            valueSync: null,
            error: false,
            isRemove: false,
            isRequired: false,
            typesValidate: []
        }
    };
    if (!data) {
        objClient = {
            ...objClient
        };
    } else {
        objClient = {
            ...objClient,
            id: data?.id ?? null,
            client_id: data.client,
            configFieldSelectClient: {
                trackBy: 'id',
                label: 'name',
                errorField: 'エンドクライアント名',
                valueSync: null,
                error: false,
                isRemove: false,
                isRequired: false,
                typesValidate: ['']
            },
            client_pic_id: data.client_pic,
            configFieldSelectClientPic: {
                trackBy: 'id',
                label: 'name',
                errorField: '担当	',
                valueSync: null,
                error: false,
                isRemove: false,
                isRequired: false,
                typesValidate: []
            }
        };
    }
    return objClient;
}

function handleRecivedInformationIP(data, form) {
    form.information_ips = [];
    if (data.information_ips.length > 0) {
        data.information_ips.forEach((itemInforIP) => {
            form.information_ips.push(initObjectInformationIP(itemInforIP));
        });
    } else {
        form.information_ips.push(initObjectInformationIP(null));
    }
    return form.information_ips;
}

function handleRecivedSponsorInformation(data, form) {
    form.sponsor_informations = [];
    if (data.sponsor_informations.length > 0) {
        data.sponsor_informations.forEach((itemSponsor) => {
            form.sponsor_informations.push(initObjectSponsor(itemSponsor));
        });
    } else {
        form.sponsor_informations.push(initObjectSponsor(null));
    }
    return form.sponsor_informations;
}

function handleRecivedTargetProduct(data, form) {
    form.target_product_lists = [];
    form.listCheckedTargetLv2 = [];
    if (data.target_product_lists.length > 0) {
        form.listCheckedTargetLv1 = data.target_product_lists.map((dataParent) => dataParent.id) ?? [];
        data.target_product_lists.forEach((itemTargetProduct) => {
            form.listCheckedTargetLv2.push(...itemTargetProduct?.children_data?.map((dataChil) => dataChil.id));
            form.target_product_lists.push(initObjectTargetProduct(itemTargetProduct));
        });
    }
    return {
        target_product_lists: form.target_product_lists,
        listCheckedTargetLv1: form.listCheckedTargetLv1,
        listCheckedTargetLv2: form.listCheckedTargetLv2
    };
}

function handleDataDetail(
    data,
    campaignStatus,
    numberOfApplications,
    numberOfInquiries,
    sns_application_condition_1,
    application_condition_1,
    application_condition_2,
    isBookmarkCampaign,
    currentUser,
    listContactInformations,
    contact_informations,
    listDataMasterCategoryProductTarget,
    listDataMasterConditionPurchase1,
    purchase_conditions,
    saleStaffObject,
    personInChargeObject,
    listItemPersons,
    progressStatus,
    form,
    phonePic,
    customerTeamPic,
    configKeyword,
    hashTag,
    listCategories,
    listDataMasterCampaignTypeChild,
    listCategoriesSub,
    subDataCustomerTeamPic,
    listDataMasterCampaignType,
    originContactInformations
) {
    if (!data) return;

    if (!data.sns_application_condition_1) {
        data.sns_application_condition_1 = {
            id: ''
        };
    } else {
        sns_application_condition_1 = data.sns_application_condition_1;
    }

    if (!data.application_condition_1) {
        data.application_condition_1 = {
            id: ''
        };
    } else {
        application_condition_1 = data.application_condition_1;
    }
    if (!data.application_condition_2) {
        data.application_condition_2 = {
            id: ''
        };
    } else {
        application_condition_2 = data.application_condition_2;
    }

    isBookmarkCampaign = currentUser.campaign_bookmarks?.some((item) => item.id === data.id) ?? false;

    let arrayDataContactInfor = [];
    if (data.contact_informations) {
        data.contact_informations.forEach((item) => {
            listContactInformations.forEach((listI) => {
                if (listI.child && listI.child.length > 0) {
                    let indexC = listI.child.findIndex((c) => {
                        return c.id == item.type?.id;
                    });
                    if (indexC >= 0) {
                        if (item.extension) {
                            contact_informations[item.type.id] = item.extension ? item.extension.content : '';
                            arrayDataContactInfor.push(item.type.id);
                        }
                    }
                }
            });

            let index = listContactInformations.findIndex((x) => {
                return x.id == item?.type?.id;
            });

            if (index >= 0) {
                if (item.type.value === '電話') {
                    contact_informations[item.type.id] =
                        data?.phone_setting && data?.phone_setting?.disable_edit_field?.field_pre_order_phone_numbers
                            ? data.phone_setting.pre_order_phone_numbers ?? data.phone_setting.reservation
                            : '';
                } else {
                    if (item.extension) {
                        contact_informations[item.type.id] = item.extension ? item.extension.content : '';
                    }
                }

                arrayDataContactInfor.push(item.type.id);
            }
        });
    }

    let arrayDataPurchase = [];
    if (data.purchase_conditions) {
        data.purchase_conditions.forEach((item) => {
            let index = listDataMasterConditionPurchase1.findIndex((x) => {
                return x.id == item.id;
            });
            if (index >= 0) {
                if (item.note) {
                    purchase_conditions[item.id] = item.note ? item.note.detail : '';
                }
                arrayDataPurchase.push(item.id);
            }
        });
    }

    let content_projects = [];
    if (data.content_projects) {
        data.content_projects.forEach((item) => {
            content_projects.push(item.id);
        });
    }

    let tool_useds = [];
    if (data.tool_useds) {
        data.tool_useds.forEach((item) => {
            tool_useds.push(item.id);
        });
    }

    let types = [];
    if (data.types) {
        data.types.forEach((item) => {
            types.push(item.id);
        });
    }

    let templates = [];
    if (data.templates) {
        data.templates.forEach((item) => {
            templates.push(item.id);
        });
    }

    if (data.part_time_employees.length == 0) {
        data.part_time_employees.push({
            id: '',
            name: ''
        });
    }
    saleStaffObject = data.sale_staff;
    personInChargeObject = data.person_in_charge;

    if (data.responsible_sub_persons.length) {
        if (data.responsible_sub_persons.length === 1) {
            if (data.responsible_sub_persons[0].sub_person_position === 1) {
                listItemPersons = [
                    ...data.responsible_sub_persons,
                    {
                        id: '',
                        name: '',
                        team: ''
                    }
                ];
            } else {
                listItemPersons = [
                    {
                        id: '',
                        name: '',
                        team: ''
                    },
                    ...data.responsible_sub_persons
                ];
            }
        } else if (data.responsible_sub_persons.length === 2) {
            listItemPersons = data.responsible_sub_persons;
        }
    }

    let idex = campaignStatus.findIndex((x) => x.id == data.progress_status);
    progressStatus = idex >= 0 ? campaignStatus[idex] : campaignStatus[0];
    data.configCampaignStartDateTime = form.configCampaignStartDateTime;
    data.configCampaignEndDateTime = form.configCampaignEndDateTime;
    data.configSecretariatStartDateTime = form.configSecretariatStartDateTime;
    data.configSecretariatEndDateTime = form.configSecretariatEndDateTime;
    data.configUrlPublishTimeFrom = form.configUrlPublishTimeFrom;
    data.configUrlPublishTimeTo = form.configUrlPublishTimeTo;
    data.configReceiptExpirationStartDateTime = form.configReceiptExpirationStartDateTime;
    data.configReceiptExpirationEndDateTime = form.configReceiptExpirationEndDateTime;
    let resultTargetProduct = handleRecivedTargetProduct(data, form);
    form = {
        ...data,
        give_away: data?.give_away ?? '0',
        source_data: data?.source_data ?? {
            type: '',
            id: ''
        },
        inquiry_office: data?.inquiry_office ?? `${data?.name} 事務局`,
        number_of_applications: data?.number_of_applications ?? [],
        total_number_of_applications: data?.total_number_of_applications ?? 0,
        number_of_inquiries: data?.number_of_inquiries ?? [],
        total_number_of_inquiries: data?.total_number_of_inquiries ?? 0,
        platform_followers_number: data?.platform_followers_number ?? [],
        advertising_activity: data?.advertising_activity ?? null,
        social_platforms: data?.social_platforms ?? [],
        give_away_related: data?.give_away_related !== null ? LIST_GIVE_AWAY_RELATED[data?.give_away_related] : null,
        account_infor_on_screen: data?.account_infor_on_screen ?? null,
        pdca_file_report: data?.pdca_file_report ?? null,
        cashback_file_report: data?.cashback_file_report ?? null,

        campaign_type: {
            id: data?.campaign_type?.id ?? null,
            key: data?.campaign_type?.key ?? '',
            value: data?.campaign_type?.value ?? ''
        },
        sns_application_condition_1: data?.sns_application_condition_1 ?? {
            id: ''
        },
        application_condition_1: data?.application_condition_1 ?? {
            id: ''
        },
        application_condition_2: data?.application_condition_2 ?? {
            id: ''
        },
        team: data?.team ?? {
            id: ''
        },
        sale_staff: data?.sale_staff ?? {
            id: ''
        },
        campaign_content_other: {
            ...form.campaign_content_other,
            ...data.campaign_content_other
        },
        information_ips: handleRecivedInformationIP(data, form),
        sponsor_informations: handleRecivedSponsorInformation(data, form),
        target_product_lists: resultTargetProduct.target_product_lists,
        listCheckedTargetLv1: resultTargetProduct.listCheckedTargetLv1,
        listCheckedTargetLv2: resultTargetProduct.listCheckedTargetLv2,
        clients: data?.clients?.map((clientItem) => initObjectClient(clientItem))
    };

    form.chatwork_rooms =
        data.chatwork_rooms.length > 0
            ? data.chatwork_rooms.map((item) => {
                  return {
                      room_id: item.room_id ?? '',
                      room_name: item.room_name ?? ''
                  };
              })
            : [
                  {
                      room_id: '',
                      room_name: ''
                  }
              ];
    form.chat_rooms = data.chat_rooms;

    handleMapDate(data, form);
    form.contact_informations = arrayDataContactInfor;
    originContactInformations = data?.contact_informations ?? [];
    form.purchase_conditions = arrayDataPurchase;
    form.content_projects = content_projects;
    form.tool_useds = tool_useds;
    form.types = types;
    form.templates = templates;
    form.give_away_information = data.give_away_information
        ? data.give_away_information
        : {
              inflow_destination: '',
              inflow_rate: '',
              attrition_rate: ''
          };
    phonePic = data.phone_pic ?? '';
    customerTeamPic = data.customer_team_pic ?? '';
    configKeyword.arrayTag = data.keywords;
    hashTag.arrayTag = data.hashtag;

    listDataMasterCampaignTypeChild = getDataCampaignTye(listDataMasterCampaignType, form, listDataMasterCampaignTypeChild);
    listCategories = [];
    listCategoriesSub = [];
    form.categories.forEach((item) => {
        listCategories.push(item.category_id);
        let index = listDataMasterCampaignTypeChild.findIndex((x) => {
            return x.id == item.category_id;
        });
        let check = listDataMasterCampaignTypeChild.findIndex((x) => {
            return x.id == item.category_id && (x.value == 'X' || x.value == 'Instagram' || x.value == 'LINE' || x.value == 'TikTok');
        });
        if (index >= 0) {
            let obj = {
                category_id: item.category_id,
                category_value: listDataMasterCampaignTypeChild.find((typeName) => typeName.id == item.category_id)?.value,
                application_condition_1: item.application_condition_1,
                application_condition_2: item.application_condition_2.map((ka2) => ka2.id),
                listCondition1: listDataMasterCampaignTypeChild[index].data,
                listCondition2: [],
                hashtag: item.hashtag ?? [],
                configHashTag: {
                    arrayTag: item.hashtag ?? []
                },
                extension: item.extension
                    ? item.extension
                    : {
                          offical_name_sns: '',
                          offical_id_sns: '',
                          number_of_circulation: '',
                          company_information: '',
                          other_type_data: '',
                          application_method: '',
                          content_other: ''
                      },
                checkSNS: check
            };

            let i = obj.listCondition1.findIndex((x) => {
                return obj.application_condition_1 && x.id == obj.application_condition_1.id;
            });
            if (i >= 0) {
                obj.listCondition2 = obj.listCondition1[i].data.map((ic2) => {
                    ic2.note = {
                        other_content: item.application_condition_2?.find((orC2) => orC2.id === ic2.id)?.note.other_content ?? ''
                    };
                    return ic2;
                });
            }
            listCategoriesSub.push(obj);
            listCategoriesSub = listCategoriesSub.sort((a, b) => a.category_id - b.category_id);
        }
    });
    subDataCustomerTeamPic = data.customer_sub_person.length > 0 ? data.customer_sub_person : [null];

    return {
        campaignStatus,
        numberOfApplications,
        numberOfInquiries,
        sns_application_condition_1,
        application_condition_1,
        application_condition_2,
        isBookmarkCampaign,
        currentUser,
        listContactInformations,
        contact_informations,
        listDataMasterCategoryProductTarget,
        listDataMasterConditionPurchase1,
        purchase_conditions,
        saleStaffObject,
        personInChargeObject,
        listItemPersons,
        progressStatus,
        form,
        phonePic,
        customerTeamPic,
        configKeyword,
        hashTag,
        listCategories,
        listCategoriesSub,
        subDataCustomerTeamPic,
        listDataMasterCampaignTypeChild,
        originContactInformations
    };
}

function getDataCampaignTye(listDataMasterCampaignType, form, listDataMasterCampaignTypeChild) {
    let index = listDataMasterCampaignType.findIndex((x) => {
        return x.id == form.campaign_type.id;
    });

    if (index >= 0) {
        listDataMasterCampaignTypeChild = listDataMasterCampaignType[index].data;
    }
    return listDataMasterCampaignTypeChild;
}

function handleMapDate(argData, form) {
    form.campaign_start_time = argData.campaign_start_date ? moment(argData.campaign_start_date).format('HH:mm') : '';
    form.campaign_end_time = argData.campaign_end_date ? moment(argData.campaign_end_date).format('HH:mm') : '';
    form.secretariat_start_time = argData.secretariat_start_date ? moment(argData.secretariat_start_date).format('HH:mm') : '';
    form.secretariat_end_time = argData.secretariat_end_date ? moment(argData.secretariat_end_date).format('HH:mm') : '';
    form.receipt_expiration_start_time = argData?.receipt_expiration_start_date ? moment(argData.receipt_expiration_start_date).format('HH:mm') : '';
    form.receipt_expiration_end_time = argData?.receipt_expiration_end_date ? moment(argData.receipt_expiration_end_date).format('HH:mm') : '';
    form.url_publish_time_from_time = argData.url_publish_time_from ? moment(argData.url_publish_time_from).format('HH:mm') : '';
    form.url_publish_time_to_time = argData.url_publish_time_to ? moment(argData.url_publish_time_to).format('HH:mm') : '';

    form.campaign_start_date = argData.campaign_start_date ? moment(argData.campaign_start_date).format(STR_DATE) : '';
    form.campaign_end_date = argData.campaign_end_date ? moment(argData.campaign_end_date).format(STR_DATE) : '';
    form.secretariat_start_date = argData.secretariat_start_date ? moment(argData.secretariat_start_date).format(STR_DATE) : '';
    form.secretariat_end_date = argData.secretariat_end_date ? moment(argData.secretariat_end_date).format(STR_DATE) : '';
    form.receipt_expiration_start_date = argData.receipt_expiration_start_date ? moment(argData.receipt_expiration_start_date).format(STR_DATE) : '';
    form.receipt_expiration_end_date = argData.receipt_expiration_end_date ? moment(argData.receipt_expiration_end_date).format(STR_DATE) : '';
    form.url_publish_time_from = argData.url_publish_time_from ? moment(argData.url_publish_time_from).format(STR_DATE) : '';
    form.url_publish_time_to = argData.url_publish_time_to ? moment(argData.url_publish_time_to).format(STR_DATE) : '';
    return form;
}

function handleMapDataSever(tableScopeOrder, campaign_master_order_scopes, has_tel_phone_setting) {
    let dataAfterMap = [];
    tableScopeOrder.forEach((itemTableScope, indexTableScope) => {
        if (campaign_master_order_scopes.length <= 0) {
            dataAfterMap.push({
                large_item: itemTableScope.id,
                medium_items: itemTableScope.data.map((itemLv2) => {
                    return {
                        medium_item: itemLv2.id,
                        small_items: itemLv2.data.map((itemLv3) => {
                            return {
                                small_item: itemLv3.id,
                                detail_items:
                                    itemLv3.data?.map((itemLv4) => {
                                        return {
                                            detail_item: itemLv4.id,
                                            extension: {
                                                content_other: commonTextValue(itemLv4),
                                                conditional_lottery: commonTextValue(itemLv4)
                                            }
                                        };
                                    }) ?? [],
                                extension: {
                                    content_other: commonTextValue(itemLv3),
                                    conditional_lottery: commonTextValue(itemLv3)
                                }
                            };
                        }),
                        extension: {
                            content_other: commonTextValue(itemLv2),
                            conditional_lottery: commonTextValue(itemLv2)
                        }
                    };
                }),
                extension: {
                    content_other: commonTextValue(itemTableScope),
                    conditional_lottery: commonTextValue(itemTableScope)
                }
            });
        } else {
            if (campaign_master_order_scopes[indexTableScope]?.id) {
                if (itemTableScope.id === campaign_master_order_scopes[indexTableScope].large_item?.id) {
                    dataAfterMap.push({
                        large_item: itemTableScope.id,
                        medium_items: itemTableScope.data.map((itemLv2) => {
                            return {
                                medium_item: itemLv2.id,
                                small_items: itemLv2.data.map((itemLv3) => {
                                    return {
                                        small_item: itemLv3.id,
                                        detail_items:
                                            itemLv3.data?.map((itemLv4) => {
                                                return {
                                                    detail_item: itemLv4.id,
                                                    extension: {
                                                        content_other: commonTextValue(itemLv4),
                                                        conditional_lottery: commonTextValue(itemLv4)
                                                    }
                                                };
                                            }) ?? [],
                                        extension: {
                                            content_other: commonTextValue(itemLv3),
                                            conditional_lottery: commonTextValue(itemLv3)
                                        }
                                    };
                                }),
                                extension: {
                                    content_other: commonTextValue(itemLv2),
                                    conditional_lottery: commonTextValue(itemLv2)
                                }
                            };
                        }),
                        extension: {
                            content_other: commonTextValue(itemTableScope),
                            conditional_lottery: commonTextValue(itemTableScope)
                        }
                    });
                } else {
                    dataAfterMap.push({
                        large_item: itemTableScope.id,
                        medium_items: itemTableScope.data.map((itemLv2) => {
                            return {
                                medium_item: itemLv2.id,
                                small_items: itemLv2.data.map((itemLv3) => {
                                    return {
                                        small_item: itemLv3.id,
                                        detail_items:
                                            itemLv3.data?.map((itemLv4) => {
                                                return {
                                                    detail_item: itemLv4.id,
                                                    extension: {
                                                        content_other: commonTextValue(itemLv4),
                                                        conditional_lottery: commonTextValue(itemLv4)
                                                    }
                                                };
                                            }) ?? [],
                                        extension: {
                                            content_other: commonTextValue(itemLv3),
                                            conditional_lottery: commonTextValue(itemLv3)
                                        }
                                    };
                                }),
                                extension: {
                                    content_other: commonTextValue(itemLv2),
                                    conditional_lottery: commonTextValue(itemLv2)
                                }
                            };
                        }),
                        extension: {
                            content_other: commonTextValue(itemTableScope),
                            conditional_lottery: commonTextValue(itemTableScope)
                        }
                    });
                }
            } else {
                dataAfterMap.push({
                    large_item: itemTableScope.id,
                    medium_items: itemTableScope.data.map((itemLv2) => {
                        return {
                            medium_item: itemLv2.id,
                            small_items: itemLv2.data.map((itemLv3) => {
                                return {
                                    small_item: itemLv3.id,
                                    detail_items:
                                        itemLv3.data?.map((itemLv4) => {
                                            return {
                                                detail_item: itemLv4.id,
                                                extension: {
                                                    content_other: commonTextValue(itemLv4),
                                                    conditional_lottery: commonTextValue(itemLv4)
                                                }
                                            };
                                        }) ?? [],
                                    extension: {
                                        content_other: commonTextValue(itemLv3),
                                        conditional_lottery: commonTextValue(itemLv3)
                                    }
                                };
                            }),
                            extension: {
                                content_other: commonTextValue(itemLv2),
                                conditional_lottery: commonTextValue(itemLv2)
                            }
                        };
                    }),
                    extension: {
                        content_other: commonTextValue(itemTableScope),
                        conditional_lottery: commonTextValue(itemTableScope)
                    }
                });
            }
        }
    });
    return {
        campaign_master_order_scopes: dataAfterMap,
        has_tel_phone_setting: handleCheckConditionTel(dataAfterMap, has_tel_phone_setting)
    };
}

function commonTextValue(itemLevel) {
    return itemLevel?.content_other;
}

function handleCheckConditionTel(campaign_master_order_scopes, has_tel_phone_setting) {
    has_tel_phone_setting = false;
    if (campaign_master_order_scopes && campaign_master_order_scopes.length > 0) {
        let getDetails = [];
        campaign_master_order_scopes.forEach((largeItem) => {
            largeItem?.medium_items?.forEach((mediumItem) => {
                getDetails.push(`${mediumItem.medium_item}`);
            });
        });
        // 2 là id của điều kiện "電話" (TEL)
        has_tel_phone_setting = getDetails.includes('2');
    }
    return has_tel_phone_setting;
}

function handleDataIPSend(dataIP) {
    dataIP = dataIP.map((itemIP) => {
        return {
            master_id: itemIP.master_id,
            note: {
                detail: itemIP.note.detail
            },
            children_data: itemIP.children_data.map((childLv1) => {
                return {
                    master_id: childLv1.master_id,
                    note: childLv1.note,
                    children_data: childLv1.children_data
                };
            })
        };
    });
    return dataIP;
}

function handleDataSponsorSend(dataSponsor) {
    dataSponsor = dataSponsor.map((itemSS) => {
        return {
            master_id: itemSS.master_id,
            note: {
                circulation_number: itemSS?.note?.circulation_number ?? '',
                circulation_distribution: itemSS?.note?.circulation_distribution ?? '',
                up_manufacturers: itemSS?.note?.up_manufacturers ?? '',
                up_manufacturers_name: itemSS?.note?.up_manufacturers_name ?? '',
                content_other: itemSS?.note?.content_other ?? ''
            },
            children_data: itemSS.children_data.map((childLv1) => {
                return {
                    master_id: childLv1.master_id,
                    note: childLv1.note,
                    children_data: childLv1.children_data
                };
            })
        };
    });
    return dataSponsor;
}

function handleDataTargetProductSend(dataTargetProduct) {
    dataTargetProduct = dataTargetProduct.map((itemTP) => {
        return {
            master_id: itemTP?.master_id ?? itemTP.id,
            note: {
                detail: itemTP?.note?.detail ?? ''
            },
            children_data: itemTP?.children_data.map((childLv1) => {
                return {
                    master_id: childLv1?.master_id ?? childLv1.id,
                    note: childLv1.note,
                    children_data: childLv1.children_data
                };
            })
        };
    });
    return dataTargetProduct;
}

function handleDataNumberOfApplicationsSend(numberOfApplications) {
    numberOfApplications = numberOfApplications.map((itemNumberA) => {
        return {
            master: {
                id: itemNumberA?.master?.id ?? null
            },
            quantity: Number(itemNumberA?.quantity) ?? 0
        };
    });
    return numberOfApplications;
}

function handleDataNumberOfInquiriesSend(numberOfInquiries) {
    numberOfInquiries = numberOfInquiries.map((itemNumberB) => {
        return {
            master_order_scope: {
                id: itemNumberB?.master_order_scope?.id ?? null
            },
            quantity: Number(itemNumberB?.quantity) ?? 0
        };
    });
    return numberOfInquiries;
}

function handleDataPlatformFollowersNumberSend(platformFollowersNumber) {
    platformFollowersNumber = platformFollowersNumber.map((itemNumberC) => {
        return {
            master: {
                id: itemNumberC?.master?.id ?? null
            },
            initial_quantity: Number(itemNumberC?.initial_quantity) ?? 0,
            final_quantity: Number(itemNumberC?.final_quantity) ?? 0,
            increase_quantity: Number(itemNumberC?.increase_quantity) ?? 0
        };
    });
    return platformFollowersNumber;
}

function handleClearDateBeforeSend(dataOrigin) {
    const formCampaignCopy = { ...dataOrigin };
    if (!formCampaignCopy.isShowPublicTime) {
        formCampaignCopy.url_publish_time_from = '';
        formCampaignCopy.url_publish_time_to = '';
    }

    if (!formCampaignCopy.isShowReceiptExpirationDate) {
        delete formCampaignCopy.receipt_expiration_start_date;
        delete formCampaignCopy.receipt_expiration_end_date;
    }
    // Xóa các thuộc tính không cần thiết
    delete formCampaignCopy.campaign_start_time;
    delete formCampaignCopy.campaign_end_time;
    delete formCampaignCopy.secretariat_start_time;
    delete formCampaignCopy.secretariat_end_time;
    delete formCampaignCopy.url_publish_time_from_time;
    delete formCampaignCopy.url_publish_time_to_time;
    delete formCampaignCopy.receipt_expiration_start_time;
    delete formCampaignCopy.receipt_expiration_end_time;

    delete formCampaignCopy.configCampaignStartDateTime;
    delete formCampaignCopy.configCampaignEndDateTime;
    delete formCampaignCopy.configSecretariatStartDateTime;
    delete formCampaignCopy.configSecretariatEndDateTime;
    delete formCampaignCopy.configUrlPublishTimeFrom;
    delete formCampaignCopy.configUrlPublishTimeTo;
    delete formCampaignCopy.configReceiptExpirationStartDateTime;
    delete formCampaignCopy.configReceiptExpirationEndDateTime;
    delete formCampaignCopy.isShowPublicTime;
    delete formCampaignCopy.isShowReceiptExpirationDate;
    delete formCampaignCopy.isShowPostcardDate;

    return formCampaignCopy;
}

function handleDataSocialPlatformsSend(socialPlatforms) {
    socialPlatforms = socialPlatforms.map((itemNumberD) => {
        return {
            master: {
                id: itemNumberD?.master?.id ?? null
            },
            note: {
                detail: itemNumberD?.note?.detail ?? ''
            }
        };
    });
    return socialPlatforms;
}

function mapDataCampaigns(
    formData,
    configKeyword,
    listItemPersons,
    purchase_conditions_data,
    contact_informations_data,
    listCategoriesSub,
    subDataCustomerTeamPic,
    arrayObjectPizes,
    tableScopeOrder,
    listnumberOfInquiries,
    originContactInformations
) {
    let objectData = JSON.parse(JSON.stringify(formData));
    objectData.campaign_type = objectData.campaign_type?.id ?? null;
    if (objectData.associated_campaign) {
        objectData.associated_campaign = {
            id: objectData.associated_campaign?.id ?? null
        };
    }
    objectData.keywords = configKeyword.arrayTag;
    let purchase_conditions = [];
    objectData.purchase_conditions.forEach((item) => {
        purchase_conditions.push({
            master_id: item,
            note: {
                detail: purchase_conditions_data[item]
            }
        });
    });
    let contact_informations = [];
    // item == 3 không gửi phone number khi đang đang lấy từ phone setting
    objectData.contact_informations.forEach((item) => {
        const idExits = originContactInformations?.find((kor) => kor.type.id === item)?.id ?? null;
        contact_informations.push({
            ...(idExits !== null && {
                id: idExits
            }),
            type_id: item,
            extension: {
                content: item !== 3 ? (contact_informations_data ? contact_informations_data[item] : '') : ''
            }
        });
    });
    let part_time_employees = [];
    objectData.part_time_employees.forEach((item) => {
        if (item.id) part_time_employees.push(item.id);
    });
    let responsible_sub_persons = [];
    listItemPersons.forEach((x, idx) => {
        if (x.id) {
            responsible_sub_persons.push({
                user_id: x.id,
                sub_person_position: idx + 1
            });
        }
    });
    objectData.purchase_conditions = purchase_conditions;
    objectData.contact_informations = contact_informations;
    objectData.responsible_sub_persons = responsible_sub_persons;

    objectData.clients = objectData.clients
        .filter((item) => item.client_id !== null)
        .map((cl) => {
            return {
                ...(cl.id !== null &&
                    cl.id !== undefined && {
                        id: cl.id
                    }),
                client_id: cl?.client_id?.id ?? null,
                client_pic_id: cl?.client_pic_id?.id ?? null
            };
        });

    objectData.prizes = [];
    objectData.part_time_employees = part_time_employees;
    objectData.categories = JSON.parse(JSON.stringify(listCategoriesSub));
    objectData.categories.forEach((item) => {
        item.hashtag = item?.configHashTag?.arrayTag ?? [];
        item.application_condition_2 = item.listCondition2
            .filter((lso2) => item.application_condition_2.includes(lso2.id))
            .map((def2) => {
                return {
                    id: def2.id,
                    note: {
                        other_content: def2?.note?.other_content ?? ''
                    }
                };
            });
        delete item.listCondition1;
        delete item.listCondition2;
    });
    delete objectData.updated_by;

    if (objectData?.client_order_source?.id) {
        objectData.client_order_source = {
            id: objectData?.client_order_source?.id
        };
    }

    objectData.customer_sub_person = subDataCustomerTeamPic[0] !== null ? subDataCustomerTeamPic.map((item) => item.id) : [];
    objectData.information_ips = objectData.information_ips[0]?.master_id == null ? [] : handleDataIPSend(objectData.information_ips);
    objectData.sponsor_informations =
        objectData.sponsor_informations[0]?.master_id == null ? [] : handleDataSponsorSend(objectData.sponsor_informations);

    // campaign_start_date
    objectData.campaign_start_date = objectData.campaign_start_time
        ? moment(objectData.campaign_start_date + ' ' + objectData.campaign_start_time, STR_DATE_TIME).format(STR_DATE_TIME)
        : objectData.campaign_start_date ?? '';

    // campaign_end_date
    objectData.campaign_end_date = objectData.campaign_end_time
        ? moment(objectData.campaign_end_date + ' ' + objectData.campaign_end_time, STR_DATE_TIME).format(STR_DATE_TIME)
        : objectData.campaign_end_date ?? '';

    // secretariat_start_date
    objectData.secretariat_start_date = objectData.secretariat_start_time
        ? moment(objectData.secretariat_start_date + ' ' + objectData.secretariat_start_time, STR_DATE_TIME).format(STR_DATE_TIME)
        : objectData.secretariat_start_date ?? '';

    // secretariat_end_date
    objectData.secretariat_end_date = objectData.secretariat_end_time
        ? moment(objectData.secretariat_end_date + ' ' + objectData.secretariat_end_time, STR_DATE_TIME).format(STR_DATE_TIME)
        : objectData.secretariat_end_date ?? '';

    // receipt_expiration_start_date
    objectData.receipt_expiration_start_date = objectData.receipt_expiration_start_time
        ? moment(objectData.receipt_expiration_start_date + ' ' + objectData.receipt_expiration_start_time, STR_DATE_TIME).format(STR_DATE_TIME)
        : objectData.receipt_expiration_start_date ?? '';

    // receipt_expiration_end_date
    objectData.receipt_expiration_end_date = objectData.receipt_expiration_end_time
        ? moment(objectData.receipt_expiration_end_date + ' ' + objectData.receipt_expiration_end_time, STR_DATE_TIME).format(STR_DATE_TIME)
        : objectData.receipt_expiration_end_date ?? '';

    // url_publish_time_from
    objectData.url_publish_time_from = objectData.url_publish_time_from_time
        ? moment(objectData.url_publish_time_from + ' ' + objectData.url_publish_time_from_time, STR_DATE_TIME).format(STR_DATE_TIME)
        : objectData.url_publish_time_from ?? '';

    // url_publish_time_to
    objectData.url_publish_time_to = objectData.url_publish_time_to_time
        ? moment(objectData.url_publish_time_to + ' ' + objectData.url_publish_time_to_time, STR_DATE_TIME).format(STR_DATE_TIME)
        : objectData.url_publish_time_to ?? '';

    objectData.event_prizes = handleMapDataPrizes(arrayObjectPizes);
    objectData.campaign_master_order_scope_partner = handleDataScopePartner(tableScopeOrder, 'register');

    const resultMapDataServe = handleMapDataSever(tableScopeOrder, objectData.campaign_master_order_scopes, objectData.has_tel_phone_setting);
    objectData.campaign_master_order_scopes = resultMapDataServe.campaign_master_order_scopes;
    objectData.has_tel_phone_setting = resultMapDataServe.has_tel_phone_setting;
    objectData.target_product_lists = handleDataTargetProductSend(objectData.target_product_lists);
    objectData.number_of_applications = handleDataNumberOfApplicationsSend(objectData.number_of_applications);
    objectData.number_of_inquiries = handleDataNumberOfInquiriesSend(listnumberOfInquiries);
    objectData.platform_followers_number = handleDataPlatformFollowersNumberSend(objectData.platform_followers_number);
    objectData.social_platforms = handleDataSocialPlatformsSend(objectData.social_platforms);
    objectData.advertising_activity = {
        id: objectData?.advertising_activity?.id ?? null
    };
    objectData.give_away_related = !objectData.give_away_related?.id ? null : objectData.give_away_related.isBoolean;
    objectData.pdca_file_report = {
        id: objectData.pdca_file_report?.id ?? null
    };
    objectData.cashback_file_report = {
        id: objectData.cashback_file_report?.id ?? null
    };

    return handleClearDateBeforeSend(objectData);
}

function onHandleIsVisibleOtherC2(objIC2, itemListCondition2) {
    return itemListCondition2.includes(objIC2.id) && objIC2.value === 'その他';
}

export {
    handleRecivedSponsorInformation,
    handleRecivedInformationIP,
    handleDataDetail,
    handleMapDataSever,
    handleMapDate,
    initObjectTargetProduct,
    handleDataSponsorSend,
    handleDataTargetProductSend,
    handleDataIPSend,
    handleDataNumberOfApplicationsSend,
    handleDataNumberOfInquiriesSend,
    handleDataPlatformFollowersNumberSend,
    handleClearDateBeforeSend,
    handleCheckConditionTel,
    handleDataSocialPlatformsSend,
    initObjectClient,
    mapDataCampaigns,
    onHandleIsVisibleOtherC2
};
