<template>
    <div>
        <ModalCommon
            :id="`ModalOrder-${id}`"
            :config="configModalCommon"
            @save="handleSaveScopeOrder()"
            @close="handleCloseScopeOrder()"
            :isView="isView"
        >
            <form class="needs-validation" autocomplete="off">
                <div class="form-group row master--scope__sticky">
                    <label class="col-sm-2 mw--form" for="validationCustom05">受注範囲</label>
                    <div class="col-sm-9">
                        <div class="d-flex flex-wrap">
                            <div style="min-width: 100px" v-for="orderLv1 in listMasterOrderScope" :key="orderLv1.id">
                                <InputCheckBox
                                    class="mr-4 py-1"
                                    :model.sync="listChecked.msOrderLv1"
                                    :id="`check_app${orderLv1.id}`"
                                    :value="orderLv1.id"
                                    :label="orderLv1.value"
                                    :disabled="isView"
                                    @change="handleChangeOrderLv1(orderLv1)"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="listChecked.msOrderLv1.length">
                    <div v-for="itemFilterLv1 in listOrderFilterLv1" :key="`${itemFilterLv1.id}-filter1`">
                        <div
                            v-if="itemFilterLv1.data?.length"
                            class="py-3 mt-3"
                            style="box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px; border-radius: 10px"
                        >
                            <div>
                                <div class="ml-1 my-1">
                                    <label class="custom-control px-3">{{ itemFilterLv1.value }} </label>
                                    <div v-if="!isView" class="col-sm-3 pl-4 pt-1">
                                        <InputCheckBox
                                            v-if="visibleCheckAllLv1.includes(itemFilterLv1.value)"
                                            class="mr-4 py-1"
                                            style="min-width: 100px"
                                            :model.sync="checkAllScopeLv1[itemFilterLv1.value]"
                                            :id="`checkAllScopeLv1-${itemFilterLv1.id}`"
                                            :value="checkAllScopeLv1[itemFilterLv1.value]"
                                            :label="$t('pageCampaign.checkAll')"
                                            @change="handleChangeAllScopeLv1(checkAllScopeLv1, itemFilterLv1.id)"
                                        />
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-sm-1"></div>
                                    <div class="col-sm-11">
                                        <div class="row">
                                            <div v-for="(dataFilterLv1, idxF1) in itemFilterLv1.data" :key="`${idxF1}-medium_item_filter1`">
                                                <InputCheckBox
                                                    class="mr-4 py-1"
                                                    style="min-width: 100px"
                                                    :model.sync="listChecked.msOrderLv2"
                                                    :id="`check_app${dataFilterLv1.id}`"
                                                    :value="dataFilterLv1.id"
                                                    :label="dataFilterLv1.value"
                                                    :disabled="isView"
                                                    @change="handleChangeOrderLv2(dataFilterLv1)"
                                                />
                                            </div>
                                        </div>

                                        <!-- Other CheckBox -->
                                        <div v-for="contentChecked1 in itemFilterLv1.data" :key="`contentChecked1_${contentChecked1.id}`">
                                            <div class="pl-0 col-sm-12 mt-3" v-if="onHanldeCheckBoxIsOther(contentChecked1, listChecked.msOrderLv2)">
                                                <div class="form-group row">
                                                    <label class="col-sm-2" for="validationCustom05">{{ contentChecked1.value }}</label>
                                                    <div class="col-sm-8">
                                                        <textarea
                                                            v-model="contentOther[contentChecked1.id]"
                                                            :rows="`2`"
                                                            class="form-control"
                                                            :disabled="isView"
                                                            :class="{
                                                                disabled_cursor: isView
                                                            }"
                                                        ></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-for="itemFilterLv2 in listOrderFilterLv2" :key="`${itemFilterLv2.id}-filter2`">
                                <div v-if="handleVisibleLv2(itemFilterLv1, itemFilterLv2)">
                                    <div v-if="itemFilterLv2.data.length > 0" style="border-top: 1px solid rgb(181 180 180 / 50%)">
                                        <div class="ml-4 my-1">
                                            <label class="custom-control px-2"
                                                >{{ itemFilterLv1.value }} <span class="font-weight-bold px-1">></span>
                                                {{ itemFilterLv2.value }}</label
                                            >
                                            <div v-if="!isView" class="col-sm-3 pl-4 pt-1">
                                                <InputCheckBox
                                                    v-if="visibleCheckAllLv2.includes(itemFilterLv2.value)"
                                                    class="mr-4 py-1"
                                                    style="min-width: 100px"
                                                    :model.sync="checkAllScopeLv2[itemFilterLv2.value]"
                                                    :id="`checkAllScopeLv2-${itemFilterLv2.id}`"
                                                    :value="checkAllScopeLv2[itemFilterLv2.value]"
                                                    :label="$t('pageCampaign.checkAll')"
                                                    @change="handleChangeAllScopeLv2(checkAllScopeLv2, itemFilterLv2.id)"
                                                />
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <div class="col-sm-1"></div>
                                            <div class="col-sm-11">
                                                <div class="row">
                                                    <div v-for="(dataFilterLv2, idxF2) in itemFilterLv2.data" :key="`${idxF2}-medium_item_filter2`">
                                                        <div v-if="handleShowOptionOneFilterLv2(itemFilterLv2)">
                                                            <InputRadio
                                                                class="mr-4"
                                                                :model.sync="listRadio.msOrderLv2[itemFilterLv2.id]"
                                                                :name="`msOrderLv2-${itemFilterLv2.id}`"
                                                                :id="`radio-${dataFilterLv2.id}`"
                                                                :value="dataFilterLv2.id"
                                                                :label="dataFilterLv2.value"
                                                                :disabled="isView"
                                                                @change="
                                                                    onChangeRadioScope(itemFilterLv2.data, listRadio.msOrderLv2[itemFilterLv2.id])
                                                                "
                                                            />
                                                        </div>
                                                        <div v-else>
                                                            <InputCheckBox
                                                                class="mr-4 py-1"
                                                                style="min-width: 100px"
                                                                :model.sync="listChecked.msOrderLv3"
                                                                :id="`check_app${dataFilterLv2.id}`"
                                                                :value="dataFilterLv2.id"
                                                                :label="dataFilterLv2.value"
                                                                :disabled="isView"
                                                                @change="handleChangeOrderLv3(dataFilterLv2)"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div :index="indexRadioContent" v-if="handleShowOptionOneFilterLv2(itemFilterLv2)">
                                                    <!-- Other Radio -->
                                                    <div
                                                        class="pl-0 col-sm-12 mt-3"
                                                        v-if="onHanldeRadioIsOther(itemFilterLv2.data, listRadio.msOrderLv2[itemFilterLv2.id])"
                                                    >
                                                        <div class="form-group row">
                                                            <label class="col-sm-2" style="" for="validationCustom05">{{
                                                                onHanleRenderNameRadio(itemFilterLv2.data, listRadio.msOrderLv2[itemFilterLv2.id])
                                                            }}</label>
                                                            <div class="col-sm-8">
                                                                <textarea
                                                                    v-model="contentOther[listRadio.msOrderLv2[itemFilterLv2.id]]"
                                                                    :rows="`2`"
                                                                    class="form-control"
                                                                    :disabled="isView"
                                                                    :class="{
                                                                        disabled_cursor: isView
                                                                    }"
                                                                ></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-else>
                                                    <!-- Other CheckBox -->
                                                    <div v-for="contentChecked2 in itemFilterLv2.data" :key="`contentChecked2_${contentChecked2.id}`">
                                                        <div
                                                            class="pl-0 col-sm-12 mt-3"
                                                            v-if="onHanldeCheckBoxIsOther(contentChecked2, listChecked.msOrderLv3)"
                                                        >
                                                            <div class="form-group row">
                                                                <label class="col-sm-2" style="" for="validationCustom05">{{
                                                                    contentChecked2.value
                                                                }}</label>
                                                                <div class="col-sm-8">
                                                                    <textarea
                                                                        v-model="contentOther[contentChecked2.id]"
                                                                        :rows="`2`"
                                                                        class="form-control"
                                                                        :disabled="isView"
                                                                        :class="{
                                                                            disabled_cursor: isView
                                                                        }"
                                                                    ></textarea>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div v-for="itemFilterLv3 in listOrderFilterLv3" :key="`${itemFilterLv3.id}-filter3`">
                                    <div v-if="handleVisibleLv3(itemFilterLv1, itemFilterLv2, itemFilterLv3)">
                                        <div v-if="itemFilterLv3?.data?.length > 0" style="border-top: 1px solid rgb(181 180 180 / 50%)">
                                            <div class="ml-4 my-1">
                                                <label class="custom-control"
                                                    >{{ itemFilterLv1.value }} <span class="font-weight-bold px-1">></span> {{ itemFilterLv2.value }}
                                                    <span class="font-weight-bold px-1">></span> {{ itemFilterLv3.value }}</label
                                                >
                                                <div v-if="!isView" class="col-sm-3 pl-5 pt-3">
                                                    <InputCheckBox
                                                        v-if="visibleCheckAllLv3.includes(itemFilterLv3.value)"
                                                        class="mr-4 py-1"
                                                        style="min-width: 100px"
                                                        :model.sync="checkAllScopeLv3[itemFilterLv3.value]"
                                                        :id="`checkAllScopeLv3-${itemFilterLv3.id}`"
                                                        :value="checkAllScopeLv3[itemFilterLv3.value]"
                                                        :label="$t('pageCampaign.checkAll')"
                                                        @change="handleChangeAllScopeLv3(checkAllScopeLv3, itemFilterLv3.id)"
                                                    />
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <div class="col-sm-1"></div>
                                                <div class="col-sm-11">
                                                    <div class="row">
                                                        <div
                                                            v-for="(dataFilterLv3, idxF3) in itemFilterLv3.data"
                                                            :key="`${idxF3}-medium_item_filter3`"
                                                        >
                                                            <div v-if="handleShowOptionOneFilterLv3(itemFilterLv3)">
                                                                <InputRadio
                                                                    class="mr-4"
                                                                    :model.sync="listRadio.msOrderLv4[itemFilterLv3.id]"
                                                                    :name="`radio-filter-lv4-${itemFilterLv3.id}`"
                                                                    :id="`radio-filter-${dataFilterLv3.id}`"
                                                                    :value="dataFilterLv3.id"
                                                                    :label="dataFilterLv3.value"
                                                                    :disabled="isView"
                                                                    @change="
                                                                        onChangeRadioScope(itemFilterLv3.data, listRadio.msOrderLv4[itemFilterLv3.id])
                                                                    "
                                                                />
                                                            </div>
                                                            <div v-else>
                                                                <InputCheckBox
                                                                    class="mr-4 py-1"
                                                                    style="min-width: 100px"
                                                                    :model.sync="listChecked.msOrderLv4"
                                                                    :id="`check_app${dataFilterLv3.id}`"
                                                                    :value="dataFilterLv3.id"
                                                                    :label="dataFilterLv3.value"
                                                                    :disabled="isView"
                                                                    @change="handleChangeOrderLv4(dataFilterLv3)"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div :index="indexRadioContent" v-if="handleShowOptionOneFilterLv3(itemFilterLv3)">
                                                        <!-- Other Radio -->
                                                        <div
                                                            class="pl-0 col-sm-12 mt-3"
                                                            v-if="onHanldeRadioIsOther(itemFilterLv3.data, listRadio.msOrderLv4[itemFilterLv3.id])"
                                                        >
                                                            <div class="form-group row">
                                                                <label class="col-sm-2" style="" for="validationCustom05"
                                                                    >{{
                                                                        onHanleRenderNameRadio(
                                                                            itemFilterLv3.data,
                                                                            listRadio.msOrderLv4[itemFilterLv3.id]
                                                                        )
                                                                    }}
                                                                </label>
                                                                <div class="col-sm-8">
                                                                    <textarea
                                                                        v-model="contentOther[listRadio.msOrderLv4[itemFilterLv3.id]]"
                                                                        :rows="`2`"
                                                                        class="form-control"
                                                                        :disabled="isView"
                                                                        :class="{
                                                                            disabled_cursor: isView
                                                                        }"
                                                                    ></textarea>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-else>
                                                        <!-- Other CheckBox -->
                                                        <div
                                                            v-for="contentChecked3 in itemFilterLv3.data"
                                                            :key="`contentChecked3_${contentChecked3.id}`"
                                                        >
                                                            <div
                                                                class="pl-0 col-sm-12 mt-3"
                                                                v-if="onHanldeCheckBoxIsOther(contentChecked3, listChecked.msOrderLv4)"
                                                            >
                                                                <div class="form-group row">
                                                                    <label class="col-sm-2" for="validationCustom05">{{
                                                                        contentChecked3.value
                                                                    }}</label>
                                                                    <div class="col-sm-8">
                                                                        <textarea
                                                                            v-model="contentOther[contentChecked3.id]"
                                                                            :rows="`2`"
                                                                            class="form-control"
                                                                            :disabled="isView"
                                                                            :class="{
                                                                                disabled_cursor: isView
                                                                            }"
                                                                        ></textarea>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </ModalCommon>
    </div>
</template>

<script>
import { InputCheckBox, InputRadio } from '@/components/Input';
import {
    funcOrderLv1,
    funcOrderLv2,
    funcOrderLv3,
    funcOrderLv4,
    funcOptionOneFilterLv2,
    funcOptionOneFilterLv3,
    funcMapDataRegisted
} from '../../index';
import ModalCommon from '@/components/Modal/main.vue';

export default {
    name: 'ModalScopeOrder',
    components: {
        ModalCommon,
        InputCheckBox,
        InputRadio
    },
    props: {
        id: {
            type: String,
            default: () => ''
        },
        dataMasterOrderScope: {
            type: Array,
            default: () => []
        },
        dataScope: {
            type: Array,
            default: () => []
        },
        dataForm: {
            type: Object,
            default: () => {}
        },
        dataContactInformations: {
            type: Object,
            default: () => {}
        },
        isShow: {
            type: Boolean,
            default: false
        },
        isView: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            form: this.dataForm,
            contact_informations: this.dataContactInformations,
            listMasterOrderScope: [],
            listOrderFilterLv1: [],
            listOrderFilterLv2: [],
            listOrderFilterLv3: [],
            listOrderFilterLv4: [],
            listChecked: {
                msOrderLv1: [],
                msOrderLv2: [],
                msOrderLv3: [],
                msOrderLv4: []
            },
            listRadio: {
                msOrderLv2: {},
                msOrderLv4: {}
            },
            configModalCommon: {
                title: '受注範囲',
                model: false,
                scrollable: true,
                hideFooter: false,
                size: '2xl',
                class: 'modal-custom-tab-basic-cp'
            },
            checkAllScopeLv1: {
                お問い合わせ: false,
                応募収集: false,
                事務局関連: false,
                発送: false,
                制作関連: false,
                SNSアカウント関連: false,
                資料系: false
            },
            checkAllScopeLv2: {
                DM: false,
                SNSツール: false,
                通数報告: false,
                抽選: false,
                重複確認: false,
                購買確認: false,
                データ入力: false,
                配信関連: false,
                郵便: false,
                デザイン: false,
                アカウント作成: false,
                '宅配便（冷凍・冷蔵）': false
            },
            checkAllScopeLv3: {
                リアル: false,
                データ: false,
                画像: false,
                SNS配信: false
            },
            visibleCheckAllLv1: ['お問い合わせ', '応募収集', '事務局関連', '発送', '制作関連', 'SNSアカウント関連', '資料系'],
            visibleCheckAllLv2: [
                'DM',
                'SNSツール',
                '通数報告',
                '抽選',
                '重複確認',
                '購買確認',
                'データ入力',
                '配信関連',
                '郵便',
                'デザイン',
                'アカウント作成',
                '宅配便（冷凍・冷蔵）'
            ],
            visibleCheckAllLv3: ['リアル', 'データ', '画像', 'SNS配信'],
            contentOther: {},
            indexRadioContent: 1
        };
    },
    methods: {
        handleChangeOrderLv1(itemLv1) {
            const resultLv1 = funcOrderLv1(
                itemLv1,
                this.listChecked,
                this.listOrderFilterLv1,
                this.listOrderFilterLv2,
                this.listOrderFilterLv3,
                this.listOrderFilterLv4,
                this.listRadio,
                this.contact_informations,
                this.contentOther
            );
            this.listChecked = resultLv1.listChecked;
            this.listOrderFilterLv1 = resultLv1.listOrderFilterLv1;
            this.listOrderFilterLv2 = resultLv1.listOrderFilterLv2;
            this.listOrderFilterLv3 = resultLv1.listOrderFilterLv3;
            this.listOrderFilterLv4 = resultLv1.listOrderFilterLv4;
            this.listRadio = resultLv1.listRadio;
            this.contact_informations = resultLv1.contact_informations;
            this.contentOther = resultLv1.contentOther;
            this.handleUncheckAllLv(itemLv1, 'lv1');
            this.$emit('changeOrderLv', {
                scopeChecked: this.listChecked,
                scopeRadio: this.listRadio
            });
        },

        handleChangeOrderLv2(itemLv2) {
            const resultLv2 = funcOrderLv2(
                itemLv2,
                this.listChecked,
                this.listOrderFilterLv2,
                this.listOrderFilterLv3,
                this.listOrderFilterLv4,
                this.listRadio,
                this.contact_informations,
                this.contentOther
            );
            this.listChecked = resultLv2.listChecked;
            this.listOrderFilterLv2 = resultLv2.listOrderFilterLv2;
            this.listOrderFilterLv3 = resultLv2.listOrderFilterLv3;
            this.listOrderFilterLv4 = resultLv2.listOrderFilterLv4;
            this.listRadio = resultLv2.listRadio;
            this.contact_informations = resultLv2.contact_informations;
            this.contentOther = resultLv2.contentOther;
            this.handleUncheckAllLv(itemLv2, 'lv2');
            this.$emit('changeOrderLv', {
                scopeChecked: this.listChecked,
                scopeRadio: this.listRadio
            });
        },

        handleChangeOrderLv3(itemLv3) {
            const resultLv3 = funcOrderLv3(
                itemLv3,
                this.listChecked,
                this.listOrderFilterLv3,
                this.listOrderFilterLv4,
                this.listRadio,
                this.contact_informations,
                this.contentOther
            );
            this.listChecked = resultLv3.listChecked;
            this.listOrderFilterLv3 = resultLv3.listOrderFilterLv3;
            this.listOrderFilterLv4 = resultLv3.listOrderFilterLv4;
            this.listRadio = resultLv3.listRadio;
            this.contact_informations = resultLv3.contact_informations;
            this.contentOther = resultLv3.contentOther;

            this.handleUncheckAllLv(itemLv3, 'lv3');
            this.$emit('changeOrderLv', {
                scopeChecked: this.listChecked,
                scopeRadio: this.listRadio
            });
        },

        handleChangeOrderLv4(itemLv4) {
            const resultLv4 = funcOrderLv4(itemLv4, this.listChecked, this.listOrderFilterLv4, this.contact_informations, this.contentOther);
            this.listChecked = resultLv4.listChecked;
            this.listOrderFilterLv4 = resultLv4.listOrderFilterLv4;
            this.contact_informations = resultLv4.contact_informations;
            this.contentOther = resultLv4.contentOther;
            this.handleUncheckAllLv(itemLv4, 'lv4');
        },

        handleVisibleLv2(itemLv1, itemLv2) {
            const result = itemLv1.data.map((item) => item.id).includes(itemLv2.id);
            return result;
        },

        handleVisibleLv3(itemLv1, itemLv2, itemLv3) {
            const resultItemLv2 = itemLv2.data.find((item) => item.id === itemLv3.id);
            if (resultItemLv2) {
                return resultItemLv2.origin_parent_id === itemLv1.id;
            }
        },

        handleSaveScopeOrder() {
            this.$emit('saveScopeOrderModal', {
                scopeChecked: this.listChecked,
                scopeRadio: this.listRadio,
                contentOther: this.contentOther
            });
            this.$emit('update:isShow', false);
        },

        handleCloseScopeOrder() {
            this.listChecked = {
                msOrderLv1: [],
                msOrderLv2: [],
                msOrderLv3: [],
                msOrderLv4: []
            };
            this.listRadio = {
                msOrderLv2: {},
                msOrderLv4: {}
            };
            this.listOrderFilterLv1 = [];
            this.listOrderFilterLv2 = [];
            this.listOrderFilterLv3 = [];
            this.listOrderFilterLv4 = [];
            this.handleMapDataRegisted();
            this.$emit('update:isShow', false);
        },

        handleMapDataRegisted() {
            funcMapDataRegisted(
                this.form,
                this.listChecked,
                this.listRadio,
                this.listOrderFilterLv1,
                this.listOrderFilterLv2,
                this.listOrderFilterLv3,
                this.listOrderFilterLv4,
                this.contentOther
            );
            this.$emit('saveScopeOrderModal', {
                scopeChecked: this.listChecked,
                scopeRadio: this.listRadio,
                contentOther: this.contentOther
            });
        },

        handleWatchOpen() {
            this.listOrderFilterLv1 = [];
            this.listOrderFilterLv2 = [];
            this.listOrderFilterLv3 = [];
            this.listOrderFilterLv4 = [];
            // check data be trả về
            this.listChecked.msOrderLv1.forEach((idOrigin) => {
                const obj = this.listMasterOrderScope.find((x) => x.id === idOrigin);
                if (obj) {
                    this.handleChangeOrderLv1(obj);
                    this.handleChangeOrderRecursive.call(this, obj);
                }
            });
        },

        handleChangeOrderRecursive(obj, level = 1) {
            if (!obj?.data) return;
            obj.data.forEach((item) => {
                switch (level) {
                    case 1:
                        this.handleChangeOrderLv2(item);
                        break;
                    case 2:
                        this.handleChangeOrderLv3(item);
                        break;
                    case 3:
                        this.handleChangeOrderLv4(item);
                        break;
                }
                // Gọi đệ quy cho cấp con tiếp theo
                this.handleChangeOrderRecursive.call(this, item, level + 1);
            });
        },

        handleShowOptionOneFilterLv2(itemFilterLv2) {
            return funcOptionOneFilterLv2(itemFilterLv2);
        },

        handleShowOptionOneFilterLv3(itemFilterLv3) {
            return funcOptionOneFilterLv3(itemFilterLv3);
        },

        handleUncheckAllLv(levelItem, lv) {
            const isAllLv1 = levelItem?.data?.every((allArr) => this.listChecked?.msOrderLv2?.includes(allArr.id));
            const findObjLv1 = this.listOrderFilterLv1.find((ot1) => ot1.id === levelItem?.parent_id);
            const findObjLv2 = this.listOrderFilterLv2.find((ot2) => ot2.id === levelItem?.parent_id);
            const findObjLv3 = this.listOrderFilterLv3.find((ot3) => ot3.id === levelItem?.parent_id);
            switch (lv) {
                case 'lv1':
                    if (this.listChecked.msOrderLv1?.includes(levelItem?.id) && isAllLv1) {
                        this.checkAllScopeLv1[levelItem?.value] = true;
                    } else {
                        this.checkAllScopeLv1[levelItem?.value] = false;
                        levelItem?.data.forEach((lv1) => {
                            if (this.checkAllScopeLv2[lv1?.value]) {
                                this.checkAllScopeLv2[lv1?.value] = false;
                            }
                            lv1?.data?.forEach((lv2) => {
                                if (this.checkAllScopeLv3[lv2?.value]) {
                                    this.checkAllScopeLv3[lv2?.value] = false;
                                }
                            });
                        });
                    }
                    break;
                case 'lv2':
                    if (findObjLv1?.data?.every((itme2) => this.listChecked.msOrderLv2.includes(itme2.id))) {
                        this.checkAllScopeLv1[findObjLv1?.value] = true;
                    } else {
                        this.checkAllScopeLv1[findObjLv1?.value] = false;
                        if (this.checkAllScopeLv2[levelItem?.value]) {
                            this.checkAllScopeLv2[levelItem?.value] = false;
                        }
                    }
                    break;
                case 'lv3':
                    if (findObjLv2?.data?.every((itme3) => this.listChecked.msOrderLv3.includes(itme3.id))) {
                        this.checkAllScopeLv2[findObjLv2?.value] = true;
                    } else {
                        this.checkAllScopeLv2[findObjLv2?.value] = false;
                        if (this.checkAllScopeLv3[levelItem?.value]) {
                            this.checkAllScopeLv3[levelItem?.value] = false;
                        }
                    }
                    break;
                case 'lv4':
                    if (findObjLv3?.data?.every((itme4) => this.listChecked.msOrderLv4.includes(itme4.id))) {
                        this.checkAllScopeLv3[findObjLv3?.value] = true;
                    } else {
                        this.checkAllScopeLv3[findObjLv3?.value] = false;
                    }
                    break;
            }
        },

        handleChangeAllScopeLv1(checkedScopeLv1, idScopeLv1) {
            const getObjLv1 = this.listOrderFilterLv1.find((oItem) => oItem.id === idScopeLv1);
            if (checkedScopeLv1[getObjLv1.value]) {
                this.listChecked.msOrderLv2 = [
                    ...new Set([...this.listChecked.msOrderLv2, ...getObjLv1?.data.map((itemCheckedLv2) => itemCheckedLv2.id)])
                ];
                if (this.listOrderFilterLv2?.length <= 0) {
                    getObjLv1?.data?.forEach((idLv1) => {
                        this.handleChangeOrderLv2(idLv1);
                    });
                } else {
                    const getArrNeed = getObjLv1?.data.filter((itG) => !this.listOrderFilterLv2.map((itme) => itme.id).includes(itG.id));
                    getArrNeed?.forEach((idLv1) => {
                        this.handleChangeOrderLv2(idLv1);
                    });
                }
            } else {
                const getIdUnchecked = getObjLv1?.data.map((itemUncheck) => itemUncheck.id);
                this.listChecked.msOrderLv2 = this.listChecked.msOrderLv2.filter((itemUncheck) => !getIdUnchecked.includes(itemUncheck));
                getObjLv1?.data?.forEach((idLv1) => {
                    this.handleChangeOrderLv2(idLv1);
                });
            }
        },

        handleChangeAllScopeLv2(checkedScopeLv2, idScopeLv2) {
            const getObjLv2 = this.listOrderFilterLv2.find((oItem) => oItem.id === idScopeLv2);
            if (checkedScopeLv2[getObjLv2.value]) {
                this.listChecked.msOrderLv3 = [
                    ...new Set([...this.listChecked.msOrderLv3, ...getObjLv2?.data.map((itemCheckedLv2) => itemCheckedLv2.id)])
                ];
                if (this.listOrderFilterLv3?.length <= 0) {
                    getObjLv2?.data?.forEach((idLv2) => {
                        this.handleChangeOrderLv3(idLv2);
                    });
                } else {
                    const getArrNeed = getObjLv2?.data.filter((itG) => !this.listOrderFilterLv3.map((itme) => itme.id).includes(itG.id));
                    getArrNeed?.forEach((idLv1) => {
                        this.handleChangeOrderLv3(idLv1);
                    });
                }
            } else {
                const getIdUnchecked = getObjLv2?.data.map((itemUncheck) => itemUncheck.id);
                this.listChecked.msOrderLv3 = this.listChecked.msOrderLv3.filter((itemUncheck) => !getIdUnchecked.includes(itemUncheck));
                getObjLv2?.data?.forEach((idLv2) => {
                    this.handleChangeOrderLv3(idLv2);
                });
            }
        },

        handleChangeAllScopeLv3(checkedScopeLv3, idScopeLv3) {
            const getObjLv3 = this.listOrderFilterLv3.find((oItem) => oItem.id === idScopeLv3);
            if (checkedScopeLv3[getObjLv3.value]) {
                this.listChecked.msOrderLv4 = [
                    ...new Set([...this.listChecked.msOrderLv4, ...getObjLv3?.data.map((itemCheckedLv3) => itemCheckedLv3.id)])
                ];
                if (this.listOrderFilterLv3?.length <= 0) {
                    getObjLv3?.data?.forEach((idLv3) => {
                        this.handleChangeOrderLv4(idLv3);
                    });
                } else {
                    const getArrNeed = getObjLv3?.data.filter((itG) => !this.listOrderFilterLv3.map((itme) => itme.id).includes(itG.id));
                    getArrNeed?.forEach((idLv1) => {
                        this.handleChangeOrderLv4(idLv1);
                    });
                }
            } else {
                const getIdUnchecked = getObjLv3?.data.map((itemUncheck) => itemUncheck.id);
                this.listChecked.msOrderLv4 = this.listChecked.msOrderLv4.filter((itemUncheck) => !getIdUnchecked.includes(itemUncheck));
                getObjLv3?.data?.forEach((idLv3) => {
                    this.handleChangeOrderLv4(idLv3);
                });
            }
        },

        onChangeRadioScope(obFilterLv, newValueRadio) {
            this.indexRadioContent < 100 ? this.indexRadioContent++ : (this.indexRadioContent = 1);
            obFilterLv.forEach((ikF) => {
                if (newValueRadio !== ikF.id && ikF.value === 'その他') {
                    this.contentOther[ikF.id] = '';
                }
            });
        },

        onHanldeCheckBoxIsOther(dataLevel, listcheckLv) {
            let isOther = false;
            if (listcheckLv.includes(dataLevel.id)) {
                if (['その他（指定なし含む）', 'その他', '条件付き抽選'].includes(dataLevel.value)) {
                    isOther = true;
                }
            } else {
                if (Array.isArray(dataLevel?.data)) {
                    this.onHanldeCheckBoxIsOther(dataLevel?.data, listcheckLv);
                }
            }

            return isOther;
        },

        onHanldeRadioIsOther(dataLevel, idRadio) {
            let isRadioOther = false;
            if (!idRadio) return isRadioOther;
            dataLevel?.forEach((lvRadio) => {
                if (idRadio === lvRadio.id) {
                    if (lvRadio.value === 'その他') {
                        isRadioOther = true;
                    }
                } else {
                    if (Array.isArray(lvRadio?.data)) {
                        this.onHanldeRadioIsOther(lvRadio?.data, idRadio);
                    }
                }
            });
            return isRadioOther;
        },

        onHanleRenderNameRadio(arrR, idR) {
            return arrR.find((oar) => oar.id === idR)?.value ?? '';
        }
    },
    watch: {
        isShow: {
            handler: function (newIsShow) {
                this.configModalCommon.model = newIsShow;
                if (newIsShow) {
                    this.handleWatchOpen();
                }
            },
            immediate: true,
            deep: true
        },
        dataScope: {
            handler: function () {
                this.handleMapDataRegisted();
            },
            immediate: true
        },
        dataMasterOrderScope: {
            handler: function (newDataMaster) {
                this.listMasterOrderScope = newDataMaster;
            },
            immediate: true
        }
    }
};
</script>
<style lang="scss" scoped>
.master--scope__sticky {
    position: sticky;
    top: -20px;
    background-color: #ffffff;
    z-index: 99;
    padding-block: 1.5rem;
}
</style>
